<!-- @format -->

<template>
	<div>
		<div
			style="
				display: flex;
				justify-content: start;
				font-weight: 900;
				background-color: rgb(76, 92, 122);
				padding: 20px;
			"
		>
			<b-button
				class="is-info margin-right-20"
				style="width: 250px"
				@click="refresh"
				>Refresh the data</b-button
			>
			<p class="content margin-right-10" style="color: white; margin-top: 10px">
				<b>Customer Invoice Rule:</b>
			</p>
			<div style="color: white">
				<b-radio
					v-model="radio"
					name="name"
					type="is-warning"
					native-value="daily"
					style="margin-top: 10px"
				>
					Daily
				</b-radio>
				<b-radio
					v-model="radio"
					name="name"
					type="is-warning"
					native-value="monthly"
				>
					Monthly
				</b-radio>
			</div>
		</div>
		<div>
			<vue-good-table
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				@on-row-dblclick="onRowDoubleClick"
				@on-cell-click="onCellClick"
				:search-options="{
					enabled: true,
				}"
				:sort-options="sortOptions"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success" :disabled="isSelectDisabled">
							Select
						</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
			<div>
				<GetSelectedDispatchesData v-if="showGetSelectedDispatchesData" />
			</div>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
// ? GraphQL
import GetDispatchesToInvoiceQuery from '@/_srcv2/pages/sale-invoice/graphql/GetDispatchesToInvoiceQuery.gql'
// import GetDispatchesToInvoiceToSameInvoiceAddressQuery from '@/_srcv2/pages/sale-invoice/graphql/GetDispatchesToInvoiceToSameInvoiceAddressQuery.gql'
// ? From NPM Package
import { reactive, computed, onMounted, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
// ?Composable scripts
// import useSearchBoxState from '@/_srcv2/components/_shared/search-box/useSearchBoxState.js'
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState'
import GetSelectedDispatchesData from '@/_srcv2/pages/sale-invoice/add-invoice/GetSelectedDispatchesData.vue'

export default {
	name: 'SearchResultListDispatchesToInvoice',
	components: { GetSelectedDispatchesData },
	setup() {
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'dispatch_date', type: 'desc' },
		}
		const radio = ref('')
		onMounted(() => {
			dispatches.value = []
			selectedDispatches.value = []
			invoiceExchangeUnit.value = ''
			invoiceAddressId.value = ''
			invoiceType.value = ''
			radio.value = 'daily'
			refetch().then(() =>
				console.log('mounted', dispatchesToInvoiceQueryResult.value),
			)
		})
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const {
			refetch,
			result: dispatchesToInvoiceQueryResult,
			onResult,
		} = useQuery(
			GetDispatchesToInvoiceQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				customer_invoice_rule: radio.value,
			}),
			options,
		)
		const getBy = (by) => {
			if (by === 'by delivery') {
				return 'Delivery'
			} else if (by === 'by e-mail') {
				return 'Email'
			} else if (by === 'by post') {
				return 'Post'
			} else {
				return '-'
			}
		}
		const dispatchesToInvoiceList = ref([])
		const getTerms = (rule, term, by) => {
			const sendInvoiceBy = getBy(by)
			if (rule === 'monthly') {
				return `M - ${term} days - ${sendInvoiceBy}`
			} else if (rule === 'daily') {
				return `D - ${term} days - ${sendInvoiceBy}`
			} else {
				return '-'
			}
		}
		onResult(() => {
			console.log(
				'onResult is fired dispatchesToInvoiceQueryResult.value',
				dispatchesToInvoiceQueryResult.value,
			)
			dispatchesToInvoiceList.value =
				dispatchesToInvoiceQueryResult.value.dispatch_headers.map((item) => {
					return {
						dispatch_number: item.dispatch_number,
						dispatch_date: item.dispatch_date,
						customer: `${item.customer.customer_title} - ${item.customer.customer_nickname} - ${item.customer.customer_id}`,
						terms: getTerms(
							item.customer.customer_invoice_rule,
							item.customer.customer_term,
							item.customer.send_invoice_by,
						),
						// ----------------------------------------------------
						customer_id: item.customer.customer_id,
						company_id: item.company_id,
						dispatch_address_id: item.dispatch_address_id,
						invoice_address_id: item.invoice_address_id,
						dispatch_exchange_rate: item.dispatch_exchange_rate,
						dispatch_exchange_unit: item.dispatch_exchange_unit,
						dispatch_type: item.dispatch_type,
					}
				})
		})

		const {
			dispatches,
			selectedDispatches,
			stateSalesInvoice,
			invoiceExchangeRate,
			invoiceExchangeUnit,
			invoiceAddressId,
			invoiceType,
			showGetSelectedDispatchesData,
			variablesQ2,
			isInvoiceSendByEmail,
		} = useSalesInvoiceState()
		variablesQ2.value = {
			company_id: '',
			customer_id: '',
			invoice_address_id: '',
			dispatch_exchange_rate: 1,
			dispatch_exchange_unit: 'kr.',
			dispatch_type: 'Inland',
		}
		const setVariablesQ2 = (item) => {
			return new Promise((resolve, reject) => {
				if (item !== null || undefined) {
					variablesQ2.value.company_id = item.company_id
					variablesQ2.value.customer_id = item.customer_id
					variablesQ2.value.invoice_address_id = item.invoice_address_id
					variablesQ2.value.dispatch_exchange_rate = item.dispatch_exchange_rate
					variablesQ2.value.dispatch_exchange_unit = item.dispatch_exchange_unit
					variablesQ2.value.dispatch_type = item.dispatch_type
					setInvoiceVariables(
						item.dispatch_exchange_rate,
						item.dispatch_exchange_unit,
						item.invoice_address_id,
						item.dispatch_type,
					)
					console.log('variablesQ2', variablesQ2)
					resolve('Done')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const setInvoiceVariables = (rate, unit, id, type) => {
			invoiceExchangeRate.value = rate
			invoiceExchangeUnit.value = unit
			invoiceAddressId.value = id
			invoiceType.value = type
		}
		// todo ---------------------------------------------------------------------------------------
		const isSelectDisabled = ref(false)
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				isSelectDisabled.value = true
				isInvoiceSendByEmail.value = params.row.terms.includes('Email')
					? true
					: false
				console.log('onCellClick params.row', params.row)
				setVariablesQ2(params.row).then(() => {
					if (
						confirm(
							'Are you sure to add the dispatch to invoice \n To cancel addition press "ok" \n To return back press "cancel"',
						)
					) {
						showGetSelectedDispatchesData.value = true
						setTimeout(() => {
							isSelectDisabled.value = false
						}, 1500)
					}
				})
			}
		}
		const onRowDoubleClick = (params) => {
			console.log('onCellClick params.row', params.row)
		}

		const columns = stateSalesInvoice.dispatchTableColumns
		const rows = computed(() => dispatchesToInvoiceList.value)
		const refresh = () => {
			refetch().then(() => window.alert(' The data is refreshed'))
		}
		return {
			sortOptions,
			onRowDoubleClick,
			onCellClick,
			columns,
			rows,
			refresh,
			radio,
			showGetSelectedDispatchesData,
			isSelectDisabled,
		}
	},
}
</script>

<style scoped></style>
