<!-- @format -->

<template>
	<div>
		<layout-default-new>
			<sub-navbar page="Sales Invoice" />
			<div>
				<section class="hero is-danger margin-bottom-20">
					<div class="hero-body">
						<p class="title">Create invoice</p>
						<p class="subtitle">Please select the dispatch to invoice</p>
					</div>
				</section>
				<div>
					<search-result-list-dispatches-to-invoice />
				</div>
			</div>
		</layout-default-new>
	</div>
</template>

<script>
// Components
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import SearchResultListDispatchesToInvoice from '@/_srcv2/pages/sale-invoice/add-invoice/SearchResultListDispatchesToInvoice.vue'

export default {
	name: 'ViewDispatchesToInvoice',
	components: {
		SubNavbar,
		SearchResultListDispatchesToInvoice,
	},
	setup() {
		return {}
	},
}
</script>

<style scoped></style>
