<!-- @format -->

<template>
	<div class="info">Getting Selected Dispatch Data...</div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState'
import GetDispatchesToInvoiceToSameInvoiceAddressQuery from '@/_srcv2/pages/sale-invoice/graphql/GetDispatchesToInvoiceToSameInvoiceAddressQuery.gql'
import { useQuery } from '@vue/apollo-composable'
import Router from '@/router'

export default {
	name: 'GetSelectedDispatchesData',
	setup() {
		const {
			ourCompany,
			customer,
			invoiceAddress,
			dispatches,
			variablesQ2,
			showGetSelectedDispatchesData,
		} = useSalesInvoiceState()
		console.log('variablesQ2', variablesQ2.value)
		const optionsQ2 = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onError, onResult } = useQuery(
			GetDispatchesToInvoiceToSameInvoiceAddressQuery,
			() => variablesQ2.value,
			optionsQ2,
		)
		onError((error) => {
			console.log('error', error)
		})
		const setFormFieldsValues = (item) => {
			console.log('--------------------> Data item', item)
			ourCompany.value = item.companies[0].company_nickname
			customer.value = item.customers[0]
			invoiceAddress.value = item.addresses[0]
			dispatches.value = item.dispatch_headers.map((item) => {
				return {
					dispatch_date: item.dispatch_date,
					dispatch_type: item.dispatch_type,
					dispatch_exchange_rate: item.dispatch_exchange_rate,
					dispatch_exchange_unit: item.dispatch_exchange_unit,
					dispatch_headers_addresses_rel_dispatch_address:
						item.dispatch_headers_addresses_rel_dispatch_address,
					_goods_transactions: item._goods_transactions,
					dispatch_lock: item.dispatch_lock,
					dispatch_number: item.dispatch_number,
					dispatch_total:
						item._goods_transactions_aggregate.aggregate.sum
							.line_price_total_credit +
						item._goods_transactions_aggregate.aggregate.sum.vat_credit,
				}
			})
		}
		onResult((result) => {
			console.log('result', result)
			setFormFieldsValues(result.data)
			showGetSelectedDispatchesData.value = false
			Router.push({ name: 'AddInvoice' })
		})
		return {}
	},
}
</script>

<style lang="css" scoped>
.info {
	margin-top: 30px;
	margin-bottom: 30px;
	font-weight: 600;
	font-size: larger;
	color: blue;
}
</style>
